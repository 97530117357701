import { cn } from 'common/utils';
import React from 'react';

const Workable = ({ size = 24, color = '#306F67', onClick }) => {
  return (
    <div className={cn({ 'cursor-pointer': onClick })}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        onClick={onClick}
      >
        <g id='workable' clipPath='url(#clip0_7591_85359)'>
          <path d='M24 0H0V24H24V0Z' fill='white' fillOpacity='0.01' />
          <mask
            id='mask0_7591_85359'
            style={{ maskType: 'luminance' }}
            maskUnits='userSpaceOnUse'
            x='1'
            y='6'
            width='16'
            height='12'
          >
            <path d='M1 6H16.0652V17.8319H1V6Z' fill='white' />
          </mask>
          <g mask='url(#mask0_7591_85359)'>
            <path
              d='M14.9479 12.4565C15.5773 11.4734 15.9463 10.3466 16.0212 9.18198C16.0397 8.26142 15.6459 7.38094 14.9479 6.78097C13.0555 5.19067 9.32843 6.03976 9.37859 9.23102C9.39737 10.436 9.98277 11.9955 11.503 13.875C11.9517 13.479 12.305 12.9869 12.5371 12.4354C8.49895 6.94743 16.9849 6.64842 12.7632 12.7333C12.4541 13.2229 12.0811 13.669 11.6538 14.0597C10.337 15.303 9.14516 15.6934 8.10495 15.5227C4.49615 14.9292 3.92027 9.54432 3.65238 6.34157H1C1 6.37632 1 6.41275 1 6.44862V7.04775C1.13871 13.4025 5.63584 22.2435 12.8232 15.2896C13.227 14.8908 13.6003 14.4623 13.9402 14.0078C14.3053 13.5101 14.6416 12.9923 14.9479 12.4565Z'

              fill={color}
            />
          </g>
          <path
            d='M21.0557 9.69388C20.5712 13.4243 18.4101 16.5808 15.1496 14.8428C15.0852 14.9322 15.0176 15.0194 14.9478 15.1032C14.6373 15.47 14.2873 15.8015 13.9043 16.0921C13.8953 16.0865 13.8855 16.0823 13.8751 16.0798C14.2148 16.2941 14.574 16.4752 14.9478 16.6212C18.9728 18.1778 22.646 14.3306 23 9.52042L21.1084 9.43579C21.0846 9.52042 21.0672 9.60673 21.0557 9.69388Z'

            fill={color}
          />
        </g>
        <defs>
          <clipPath id='clip0_7591_85359'>
            <rect width='24' height='24' fill='white' />

          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Workable;
