import Download from 'assets/icons/Download';
import Left from 'assets/icons/Left';
import Button from 'common/components/Button';
import Divider from 'common/components/Divider';
import Loader from 'common/components/Loader';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import insightsTracker from 'common/insights_tracker';
import {
  useGetSubmissionCurriculumQuery,
  useGetSubmissionQuery,
  useGetSubmissionQuestionsQuery,
  useGetSubmissionTestsQuery,
  useUpdateSubmissionAIInfoMutation,
} from 'common/slices/submissionApi.slice';
import { cn } from 'common/utils';
import React, { useEffect, useState } from 'react';

import CandidateCurriculum from './CandidateCurriculum';
import CandidateEvaluation from './CandidateEvaluation';
import CandidateGeneralView from './CandidateGeneralView';
import CandidateHeader from './CandidateHeader';
import CandidateNotes from './CandidateNotes';
import CandidateQuestions from './CandidateQuestions';
import OptionsMenu from './OptionsMenu';

const CandidateDrawer = ({ submissionSlug, isOpen, closeDrawer }) => {
  const toast = useToast();
  const [tab, setTab] = useState('general');
  const { t } = useTranslation('offers\\details');

  const [submission, setSubmission] = useState(null);
  const [notesSeparatorLength, setNotesSeparatorLength] = useState(0);

  useEffect(() => {
    if (isOpen && submission) {
      insightsTracker.submissionDetailPageView(submission.id);
    }
  }, [isOpen]);

  const [updateSubmissionAIInfo] = useUpdateSubmissionAIInfoMutation();
  const [isAIReviewSyncing, setAIReviewSyncing] = useState(false);

  const handleAIReview = async () => {
    setAIReviewSyncing(true);
    try {
      await updateSubmissionAIInfo({ submissionSlug }).unwrap();
      toast.newToast('positive', t('toasts.success-ai-review'));
    } catch (err) {
      setAIReviewSyncing(false);
      switch (err?.status) {
        case 403:
          toast.newToast('negative', t('toasts.forbidden'));
          break;
        default:
          toast.newToast('negative', t('toasts.something-wrong'));
      }
    }
  };

  const { data: initialSubmission, isFetching: isLoadingSubmission } =
    useGetSubmissionQuery({ submissionSlug });

  useEffect(() => {
    !isLoadingSubmission && setSubmission(initialSubmission);
  }, [initialSubmission]);

  useEffect(() => {
    if (!isLoadingSubmission) {
      setNotesSeparatorLength(submission?.notes?.length || 0);
    }
  }, [submission]);

  const {
    data: cvUrl,
    error,
    isFetching: isLoadingCurriculum,
  } = useGetSubmissionCurriculumQuery({ submissionSlug });
  const { data: tests, isFetching: isLoadingTests } =
    useGetSubmissionTestsQuery({ submissionSlug });
  const { data: questions, isFetching: isLoadingQuestions } =
    useGetSubmissionQuestionsQuery({ submissionSlug });

  const handleClose = () => {
    closeDrawer();
  };

  useEffect(() => {
    if (!isOpen) {
      setTab('general');
    }
  }, [isOpen]);

  useEffect(() => {
    if (error) {
      toast.newToast('negative', error?.data?.detail);
    }
  }, [error]);

  const isLoading =
    isLoadingSubmission ||
    isLoadingCurriculum ||
    isLoadingTests ||
    isLoadingQuestions ||
    !submission;

  const options = [
    {
      label: t('candidate-drawer-options-menu.generate-ai-review'),
      action: handleAIReview,
    },
  ].filter((option) => option.action);

  return (
    <div className='w-full min-h-full flex flex-col p-5 flex-1'>
      <div className='ml-5 mb-6'>
        <Button size='icon' variant='primary' onClick={handleClose}>
          <Left size='16px' color='#FFFFFF' />
        </Button>
      </div>
      <div className='flex items-center justify-between mx-5 mb-8'>
        <CandidateHeader
          submission={submission}
          isLoading={isLoadingSubmission}
        />
      </div>
      <div className='flex flex-col mx-5 mb-4'>
        <div className='flex justify-between mb-1.5'>
          <ul className='flex items-center gap-4 mb-0.5 z-10'>
            <li
              className={cn(
                'font-semibold select-none text-sonic-silver cursor-pointer',
                {
                  'text-vibrant-orange decoration-2 underline underline-offset-[20px] cursor-auto':
                    tab === 'general',
                },
              )}
              onClick={() => setTab('general')}
            >
              {t('general-view')}
            </li>
            <li
              className={cn(
                'font-semibold select-none text-sonic-silver cursor-pointer',
                {
                  'text-vibrant-orange decoration-2 underline underline-offset-[20px] cursor-auto':
                    tab === 'cv',
                },
              )}
              onClick={() => setTab('cv')}
            >
              {t('cv')}
            </li>
            <li
              className={cn(
                'flex justify-center items-center gap-1 cursor-pointer',
                { 'cursor-auto ': tab === 'evaluation' },
              )}
              onClick={() => setTab('evaluation')}
            >
              <p
                className={cn('font-semibold select-none text-sonic-silver', {
                  'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                    tab === 'evaluation',
                })}
              >
                {t('evaluation')}
              </p>
              {tests && (
                <div
                  className={cn(
                    'flex items-center justify-center rounded-full w-5 h-5 text-xs font-semibold text-white bg-sonic-silver',
                    {
                      'cursor-default bg-vibrant-orange': tab === 'evaluation',
                    },
                  )}
                >
                  {tests.length}
                </div>
              )}
            </li>
            <li
              className={cn(
                'flex justify-center items-center gap-1 cursor-pointer',
                { 'cursor-auto ': tab === 'questions' },
              )}
              onClick={() => setTab('questions')}
            >
              <p
                className={cn('font-semibold select-none text-sonic-silver', {
                  'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                    tab === 'questions',
                })}
              >
                {t('questions')}
              </p>
              {questions && (
                <div
                  className={cn(
                    'flex items-center justify-center rounded-full w-5 h-5 text-xs font-semibold text-white bg-sonic-silver',
                    { 'cursor-default bg-vibrant-orange': tab === 'questions' },
                  )}
                >
                  {questions.questions.length}
                </div>
              )}
            </li>
            <li
              className={cn(
                'flex justify-center items-center gap-1 cursor-pointer',
                { 'cursor-auto ': tab === 'notes' },
              )}
              onClick={() => setTab('notes')}
            >
              <p
                className={cn('font-semibold select-none text-sonic-silver', {
                  'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                    tab === 'notes',
                })}
              >
                {t('tabNotes.notes')}
              </p>
              {submission?.notes && (
                <div
                  className={cn(
                    'flex items-center justify-center rounded-full w-5 h-5 text-xs font-semibold text-white bg-sonic-silver',
                    { 'cursor-default bg-vibrant-orange': tab === 'notes' },
                  )}
                >
                  {notesSeparatorLength}
                </div>
              )}
            </li>
          </ul>
          <div className='flex items-center gap-4'>
            <a href={cvUrl} rel='noreferrer' target='_blank'>
              <Button variant='primary' disabled={!cvUrl}>
                <Download size='16px' color='#FFFFFF' />
                {t('download-cv')}
              </Button>
            </a>
            <OptionsMenu
              options={options}
              isAIReviewSyncing={isAIReviewSyncing}
            />
          </div>
        </div>
        <Divider />
      </div>
      <div className='relative h-full w-full flex-1'>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {tab === 'general' && (
              <CandidateGeneralView submission={submission} />
            )}
            {tab === 'cv' && <CandidateCurriculum cvUrl={cvUrl} />}
            {tab === 'evaluation' && <CandidateEvaluation tests={tests} />}
            {tab === 'questions' && (
              <CandidateQuestions questions={questions?.questions} />
            )}
            {tab === 'notes' && (
              <CandidateNotes
                submission={submission}
                setSubmission={setSubmission}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CandidateDrawer;
