import Button from 'common/components/Button';
import Card from 'common/components/Card';
import { OnboardingStatus } from 'common/enums';
import useNavigate from 'common/hooks/useNavigate';
import useTranslation from 'common/hooks/useTranslation';
import {
  selectCurrentCompany,
  selectCurrentToken,
  selectUserCompanies,
  setCurrentCompany,
} from 'common/slices/auth.slice';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import CompanySelect from './components/CompanySelect';

const CompanySelectorForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('auth\\company-selector');

  const token = useSelector(selectCurrentToken);
  const currentCompany = useSelector(selectCurrentCompany);

  useEffect(() => {
    if (token && currentCompany) {
      if (
        currentCompany?.onboarding_status !== OnboardingStatus.done &&
        currentCompany?.onboarding_status !== OnboardingStatus.skipped
      ) {
        navigate('/onboarding');
        return;
      }
      navigate('/dashboard');
    }
  }, [token, currentCompany]);

  const validationSchema = Yup.object().shape({
    company: Yup.string().required(t('company-required')),
  });

  const userCompanies = useSelector(selectUserCompanies) || [];

  const companyOptions = userCompanies.map((company) => ({
    label: company.name,
    value: company.id,
    location: company.location,
  }));

  const initialValues = {
    company: userCompanies[0].id,
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const selectedCompany = userCompanies.find(
      (company) => company.id === values.company,
    );

    dispatch(setCurrentCompany(selectedCompany));

    if (
      currentCompany?.onboarding_status !== OnboardingStatus.done &&
      currentCompany?.onboarding_status !== OnboardingStatus.skipped
    ) {
      navigate(`${selectedCompany.slug}/onboarding`, true);
      setSubmitting(false);
      return;
    }

    navigate(`${selectedCompany.slug}/dashboard`, true);

    setSubmitting(false);
  };

  return (
    <Card shadowed>
      <div className='bg-vibrant-yellow rounded-t-lg h-2 w-full' />
      <div className='flex flex-col justify-center pt-10 md:px-[72px] px-5 pb-12 w-full'>
        <div className='w-[300px]'>
          <h1 className='text-center font-semibold text-3xl text-dark-blue pb-6'>
            {t('welcome')}
          </h1>
          <h3 className='text-center font-normal text-base text-dark-blue pb-6'>
            {t('description')}
          </h3>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form className='flex flex-col gap-4 w-full'>
              <CompanySelect
                name='company'
                label={t('company')}
                options={companyOptions}
                isWithAvatar={true}
              />
              <Button variant='primary' type='submit'>
                {t('next')}
              </Button>
            </Form>
          </Formik>
        </div>
      </div>
    </Card>
  );
};

export default CompanySelectorForm;
