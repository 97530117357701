import { createSlice } from '@reduxjs/toolkit';

const safeParse = (key) => {
  const item = localStorage.getItem(key);
  try {
    return item ? JSON.parse(item) : null;
  } catch (e) {
    return null;
  }
};

const initialState = {
  accessToken: safeParse('accessToken'),
  currentUser: safeParse('currentUser'),
  currentCompany: safeParse('currentCompany'),
  currentUserEmail: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { access_token: accessToken, token_type, ...rest } = action.payload;

      state.accessToken = accessToken;
      state.currentUser = rest;

      if (state.currentUser?.companies?.length === 1) {
        state.currentCompany = state.currentUser.companies[0];
        localStorage.setItem(
          'currentCompany',
          JSON.stringify(state.currentCompany),
        );
      }

      localStorage.setItem('accessToken', JSON.stringify(state.accessToken));
      localStorage.setItem('currentUser', JSON.stringify(state.currentUser));
    },
    setCurrentCompany: (state, action) => {
      state.currentCompany = action.payload;
      localStorage.setItem(
        'currentCompany',
        JSON.stringify(state.currentCompany),
      );
    },
    updateCurrentCompany: (state, action) => {
      state.currentCompany = action.payload;

      if (state.currentUser) {
        const updatedCompanies = state.currentUser.companies.map((company) =>
          company.id === state.currentCompany.id
            ? state.currentCompany
            : company,
        );

        state.currentUser = {
          ...state.currentUser,
          companies: updatedCompanies,
        };
      }

      localStorage.setItem(
        'currentCompany',
        JSON.stringify(state.currentCompany),
      );

      localStorage.setItem('currentUser', JSON.stringify(state.currentUser));
    },
    setUserEmail: (state, action) => {
      const { email } = action.payload;
      localStorage.setItem('currentUserEmail', JSON.stringify(email));
    },
    logOut: (state, action) => {
      state.accessToken = null;
      state.currentUser = null;
      state.currentCompany = null;

      localStorage.removeItem('accessToken');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentUserEmail');
      localStorage.removeItem('currentCompany');
    },
  },
});

export const {
  setCurrentCompany,
  updateCurrentCompany,
  setCredentials,
  setUserEmail,
  logOut,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentToken = (state) => state.auth.accessToken;
export const selectCurrentCompany = (state) => state.auth.currentCompany;
export const selectUserCompanies = (state) => state.auth.currentUser?.companies;
export const selectUserGender = (state) => {
  const gender = state.auth.currentUser?.gender;
  return gender && gender === 1 ? 'male' : 'female';
};
export const selectUser = (state) => state.auth.currentUser;
