import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { useCallback, useEffect } from 'react';

import Toolbar from './Toolbar';

const TipTapEditor = ({ field, form, showToast, ...props }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Bold,
      Italic,
      Underline,
      BulletList,
      OrderedList,
      ListItem,
      Link.configure({
        openOnClick: false,
        autolink: true,
        defaultProtocol: 'https',
      }),
    ],
    content: field.value || '',
    onUpdate: ({ editor }) => {
      form.setFieldValue(field.name, editor.getHTML());
    },
  });

  const setLink = useCallback(() => {
    if (!editor) return;

    const { state, commands } = editor;
    const { selection } = state;
    const previousUrl = editor.getAttributes('link').href || '';

    if (selection.empty) {
      alert('Please select some text to apply the link.');
      return;
    }

    let url = window.prompt('URL', previousUrl);

    if (url === null) return;

    if (url.trim() === '') {
      // Remove the link
      commands.unsetLink(); // Use unsetLink instead of removeMark
      showToast('positive', 'Link removed successfully');
      return;
    }

    if (!/^(https?|ftp):\/\//i.test(url)) {
      if (!previousUrl) {
        url = `https://${url}`;
      }
    }

    // Add or update the link
    commands.setLink({ href: url });
    editor.commands.focus();

    showToast('positive', 'Link added successfully');
  }, [editor, showToast]);

  useEffect(() => {
    return () => {
      if (editor) {
        editor.destroy();
      }
    };
  }, [editor]);

  return (
    <div className='rich-text-editor border border-gray-100 rounded-md p-2'>
      {editor && <Toolbar editor={editor} setLink={setLink} />}
      <EditorContent
        editor={editor}
        className='prose prose-sm max-w-full'
        {...props}
      />
    </div>
  );
};

export default TipTapEditor;
