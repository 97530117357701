import Button from 'common/components/Button';
import Loader from 'common/components/Loader';
import Toggle from 'common/components/Toggle';
import { SettingsContext } from 'common/contexts/SettingsContext';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import { useGetNotificationsMapQuery } from 'common/slices/settingsApi.slice';
import React, { useContext, useEffect, useState } from 'react';

const ToggleOption = ({ label, description, isActive, onClick }) => (
  <div className='flex items-center justify-between border-b border-gray-300 py-3 px-4'>
    <div className='flex-1'>
      <span className='text-base font-semibold'>{label}</span>
      <p className='text-gray-500 text-sm mt-1'>{description}</p>
    </div>
    <Toggle isActive={isActive} onClick={onClick} />
  </div>
);

const UserNotificationsForm = () => {
  const [groupedNotifications, setGroupedNotifications] = useState([]);
  const [globalToggle, setGlobalToggle] = useState(false);
  const { updateCurrentUserSettings, isUpdatingUserSettings } =
    useContext(SettingsContext);
  const { t, i18n } = useTranslation('settings\\index');
  const toast = useToast();
  const { data: notificationsMap, refetch } = useGetNotificationsMapQuery();

  const handleGlobalToggle = async (value) => {
    setGlobalToggle(value);
    try {
      const allSettings = groupedNotifications.flatMap((group) =>
        group.notifications.map((notification) => ({
          name: notification.slug,
          value: value.toString(),
        })),
      );
      await updateCurrentUserSettings(allSettings);
      await refetch();

      toast.newToast('positive', t('toasts.success'));
    } catch (err) {
      toast.newToast(
        'negative',
        t(err?.status === 403 ? 'toasts.forbidden' : 'something-wrong'),
      );
    }
  };

  const handleRestoreDefaults = async () => {
    try {
      const resetSettings = groupedNotifications.flatMap((group) =>
        group.notifications.map((notification) => ({
          name: notification.slug,
          value: notification.default_value.toString(),
        })),
      );
      await updateCurrentUserSettings(resetSettings);
      await refetch();

      toast.newToast('positive', t('toasts.restoreSuccess'));
    } catch (err) {
      toast.newToast(
        'negative',
        t(err?.status === 403 ? 'toasts.forbidden' : 'something-wrong'),
      );
    }
  };

  const updateSetting = async (slug, value) => {
    try {
      await updateCurrentUserSettings([
        { name: slug, value: value.toString() },
      ]);
      await refetch();

      toast.newToast('positive', t('toasts.success'));
    } catch (err) {
      toast.newToast(
        'negative',
        t(err?.status === 403 ? 'toasts.forbidden' : 'something-wrong'),
      );
    }
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      await refetch();
    };

    fetchNotifications();
  }, [i18n.language]);

  useEffect(() => {
    if (notificationsMap) {
      const grouped = (notificationsMap.entities || [])
        .map((entity) => ({
          ...entity,
          notifications: (notificationsMap.notifications || []).filter(
            (n) => n.entity === entity.entity,
          ),
        }))
        .filter((group) => group.notifications.length);
      setGroupedNotifications(grouped);

      const allEnabled = grouped
        .flatMap((g) => g.notifications)
        .every((n) => n.value);
      setGlobalToggle(allEnabled);
    }
  }, [notificationsMap]);

  if (!groupedNotifications.length) return <Loader />;

  return (
    <div className='flex flex-col w-full px-6'>
      <div className='flex items-center justify-between py-4'>
        <div className='flex items-center'>
          <label className='text-lg font-semibold text-gray-700 mr-4'>
            {globalToggle ? t('untoggle-all') : t('toggle-all')}
          </label>
          <Toggle
            isActive={globalToggle}
            onClick={() => handleGlobalToggle(!globalToggle)}
          />
        </div>
        <Button variant='primary' onClick={handleRestoreDefaults}>
          {t('restore-defaults')}
        </Button>
      </div>

      <div className='grid grid-cols-2 gap-12 mt-6'>
        {groupedNotifications.map((group) => (
          <div key={group.entity} className='mb-6'>
            <h3 className='text-lg font-semibold uppercase text-gray-700 mb-4 pb-2 bg-white sticky top-0 z-10'>
              {group.name}
            </h3>
            {group.notifications.map((notification) => (
              <ToggleOption
                key={notification.slug}
                label={notification.label}
                description={notification.description}
                isActive={notification.value}
                onClick={() =>
                  updateSetting(notification.slug, !notification.value)
                }
              />
            ))}
          </div>
        ))}
      </div>
      {isUpdatingUserSettings && <Loader />}
    </div>
  );
};

export default UserNotificationsForm;
