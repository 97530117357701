import LabelledInput from 'common/components/LabelledInput';
import WorkSelect from 'common/components/WorkSelect';
import useTranslation from 'common/hooks/useTranslation';
import { Form, Formik } from 'formik';
import React from 'react';

export const InformationTab = ({ offer }) => {
  const { t } = useTranslation('offers\\new');

  return (
    <Formik
      initialValues={{
        positionName: offer.name || '',
        minSalary: offer.salary_min || '',
        maxSalary: offer.salary_max || '',
        vacancies: offer.n_vacancies || '',
        location: offer.location || '',
        workType: offer.work_type || '',
      }}
      onSubmit={(values) => {
        // Handle form submission
        console.log(values);
      }}
    >
      {(formik) => (
        <Form>
          <div className='grid grid-cols-1 gap-6 pt-6'>
            {/* Position Name */}
            <div className='w-full'>
              <LabelledInput
                label={t('position-name')}
                name='positionName'
                type='text'
                value={formik.values.positionName}
                placeholder={t('position-name')}
                disabled={true}
              />
            </div>

            {/* Salary Fields */}
            <div className='w-full flex gap-6'>
              <div className='w-1/2'>
                <LabelledInput
                  label={t('minimum-salary')}
                  name='minSalary'
                  type='text'
                  value={formik.values.minSalary}
                  placeholder='00,00$'
                  disabled={true}
                />
              </div>
              <div className='w-1/2'>
                <LabelledInput
                  label={t('maximum-salary')}
                  name='maxSalary'
                  type='text'
                  value={formik.values.maxSalary}
                  placeholder='00,00$'
                  disabled={true}
                />
              </div>
            </div>

            {/* Work Type and Location */}
            <div className='w-full flex gap-6'>
              <div className='w-1/2'>
                <WorkSelect
                  name='workType'
                  label={t('work-type')}
                  isDisabled={true}
                />
              </div>
              <div className='w-1/2'>
                <LabelledInput
                  label={t('location')}
                  name='location'
                  type='text'
                  value={formik.values.location}
                  placeholder={t('enter-location')}
                  disabled={true}
                />
              </div>
            </div>

            {/* Number of Vacancies */}
            <div className='w-full'>
              <LabelledInput
                label={t('number-vacancies')}
                name='vacancies'
                type='text'
                value={formik.values.vacancies}
                placeholder='1'
                disabled={true}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
