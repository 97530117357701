import { apiSlice } from 'common/slices/api.slice';

export const settingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationsMap: builder.query({
      query: () => ({
        url: '/settings/notifications',
      }),
      providesTags: [''],
    }),
  }),
});

export const { useGetNotificationsMapQuery } = settingsApiSlice;
