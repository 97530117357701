import { Listbox, Transition } from '@headlessui/react';
import Comment from 'assets/icons/Comment';
import Down from 'assets/icons/Down';
import Help from 'assets/icons/Help';
import Inbox from 'assets/icons/Inbox';
import LineChart from 'assets/icons/LineChart';
import Page from 'assets/icons/Page';
import Search from 'assets/icons/Search';
import Settings from 'assets/icons/Settings';
import SidebarClose from 'assets/icons/SidebarClose';
import Up from 'assets/icons/Up';
import { Logo, LogoName } from 'assets/logos';
import Avatar from 'common/components/Avatar';
import Divider from 'common/components/Divider';
import useNavigate from 'common/hooks/useNavigate';
import useTranslation from 'common/hooks/useTranslation';
import { apiSlice } from 'common/slices/api.slice';
import {
  selectCurrentCompany,
  selectUserCompanies,
  setCurrentCompany,
} from 'common/slices/auth.slice';
import { cn } from 'common/utils';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ShowFeature } from 'split';

import SidebarButton from './SidebarButton';

const Sidebar = ({ isCollapsed, setCollapsed }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('common\\sidebar');

  const [selected, setSelected] = useState(location.pathname);

  const currentCompany = useSelector(selectCurrentCompany);
  const userCompanies = useSelector(selectUserCompanies);

  useEffect(() => {
    setSelected(location.pathname);
  }, [location]);

  const handleCompanyUpdate = (company) => {
    dispatch(setCurrentCompany(company));
    dispatch(apiSlice.util.resetApiState());

    navigate(`${company.slug}/dashboard`, true);

    console.log('New selected company:', company.slug);
  };

  const openCompanySelector = (open) => {
    if (open && isCollapsed) setCollapsed();

    return open;
  };

  return (
    <div className='bg-white text-cool-grey shadow z-20'>
      <div className='flex flex-col h-full'>
        <div className='flex flex-col'>
          <div
            className={cn('flex items-center', 'min-h-[74px] px-4', {
              'justify-between': !isCollapsed,
              'justify-center transition-none w-full': isCollapsed,
            })}
          >
            <div className='flex gap-1.5'>
              <Logo
                onClick={isCollapsed ? setCollapsed : null}
                className={cn({ 'cursor-pointer': isCollapsed })}
              />
              {!isCollapsed && <LogoName alt='logo-name' />}
            </div>
            {!isCollapsed && (
              <SidebarClose
                size='24px'
                onClick={() => {
                  setCollapsed();
                }}
              />
            )}
          </div>
          <ShowFeature featureFlag='WEB_NO_ACTION_UI'>
            <div
              className={cn(
                'h-12 mb-6',
                'bg-ghost-white text-cool-grey rounded',
                {
                  'mx-3': !isCollapsed,
                  'mx-5': isCollapsed,
                },
              )}
            >
              <div className='relative flex items-center w-full h-12 overflow-hidden'>
                <div className='grid place-items-center h-full w-12'>
                  <Search
                    size='18px'
                    onClick={isCollapsed ? setCollapsed : null}
                  />
                </div>
                <input
                  className={cn(
                    'h-12 w-full outline-none',
                    'bg-ghost-white rounded pr-2',
                    'placeholder:font-semibold',
                    {
                      hidden: isCollapsed,
                    },
                  )}
                  type='text'
                  id='search'
                  placeholder='Search'
                />
              </div>
            </div>
          </ShowFeature>
        </div>
        <Divider />
        <div className='my-6 flex-1 flex flex-col justify-between'>
          <ul className='w-full flex flex-col justify-between items-center'>
            <SidebarButton
              icon={
                <Page
                  color={
                    selected.includes('/dashboard') ? '#F16347' : '#8D91A0'
                  }
                />
              }
              link='/dashboard'
              label={t('dashboard')}
              isCollapsed={isCollapsed}
              isSelected={selected.includes('/dashboard')}
            />
            <SidebarButton
              icon={
                <Inbox
                  color={selected.includes('/offers') ? '#F16347' : '#8D91A0'}
                />
              }
              link='/offers'
              label={t('offers')}
              isCollapsed={isCollapsed}
              isSelected={selected.includes('/offers')}
            />
            <SidebarButton
              icon={
                <LineChart
                  color={selected.includes('/talent') ? '#F16347' : '#8D91A0'}
                />
              }
              link='/talent'
              label={t('talent')}
              isCollapsed={isCollapsed}
              isSelected={selected.includes('/talent')}
            />
            <SidebarButton
              icon={
                <Comment
                  color={selected.includes('messages') ? '#F16347' : '#8D91A0'}
                />
              }
              link='/messages'
              label={t('messages')}
              isCollapsed={isCollapsed}
              isSelected={selected.includes('/messages')}
            />
          </ul>
          <ul className='w-full flex flex-col justify-between items-center'>
            <SidebarButton
              icon={
                <Settings
                  color={selected.includes('/settings') ? '#F16347' : '#8D91A0'}
                />
              }
              link='/settings'
              label={t('settings')}
              isCollapsed={isCollapsed}
              isSelected={selected.includes('/settings')}
            />
            <SidebarButton
              icon={<Help color='#8D91A0' />}
              link='https://www.validatetalent.ai/faqs'
              label={t('help')}
              isCollapsed={isCollapsed}
              isSelected={selected.includes('help')}
              external={true}
            />
            <div className='h-10 w-full flex flex-row items-center px-5 text-xs'>
              <span>⚡ by Validate - 1.5.1</span>
            </div>
          </ul>
        </div>
        <div className='relative'>
          <Listbox value={currentCompany} onChange={handleCompanyUpdate}>
            {({ open }) => (
              <>
                <div className='relative'>
                  <Listbox.Button
                    className={cn(
                      'h-[68px] w-full z-40 px-5 flex items-center bg-ghost-white select-none',
                      {
                        'justify-between': !isCollapsed,
                        'justify-center transition-none w-full': isCollapsed,
                        'cursor-pointer': userCompanies?.length > 1,
                      },
                    )}
                  >
                    <Avatar size='small' name={currentCompany?.name[0]} />
                    <div
                      className={cn('flex-1 flex flex-col items-start', {
                        hidden: isCollapsed,
                        'ml-4': userCompanies?.length === 1,
                        'max-w-[120px]': userCompanies?.length > 1,
                      })}
                    >
                      <h4
                        className={cn(
                          'text-dark-charcoal text-sm font-semibold',
                          {
                            'max-w-[120px] truncate': userCompanies?.length > 1,
                          },
                        )}
                      >
                        {currentCompany?.name}
                      </h4>
                      <p className='truncate text-xs text-sonic-silver'>
                        {currentCompany?.location}
                      </p>
                    </div>
                    {!isCollapsed &&
                      userCompanies?.length > 1 &&
                      (open ? (
                        <Up color='#342E30' size='24px' />
                      ) : (
                        <Down color='#342E30' size='24px' />
                      ))}
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    show={openCompanySelector(open)}
                    leave='transition ease-in duration-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <Listbox.Options
                      className={cn(
                        'absolute -translate-y-full',
                        'flex flex-col max-h-[240px]',
                        'top-0 left-0 mt-0.5 w-full',
                        'rounded-t bg-white shadow-[0px_-1px_0px_0px_#00000024]',
                        'overflow-y-auto overflow-x-hidden scrollbar scrollbar-w-1',
                        'scrollbar-thumb-vibrant-orange scrollbar-thumb-rounded',
                        'scrollbar-track-rounded ',
                        'pt-4 pb-2 px-4',
                      )}
                    >
                      {userCompanies?.map((company, index) => (
                        <Listbox.Option
                          key={index}
                          value={company}
                          className={cn(
                            'flex min-h-14 gap-2.5 px-2 items-center mb-1 rounded',
                            {
                              'hover:bg-ghost-white cursor-pointer':
                                company.id !== currentCompany?.id,
                              'bg-vibrant-orange bg-opacity-10 select-none':
                                company.id === currentCompany?.id,
                            },
                          )}
                        >
                          <Avatar size='small' name={company.name[0]} />
                          <div className='flex flex-col bg- items-start'>
                            <p className='text-dark-charcoal max-w-[140px] truncate'>
                              {company.name}
                            </p>
                            <p className='text-xs text-sonic-silver'>
                              {company.location}
                            </p>
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
