import { cn } from 'common/utils';
import React from 'react';

const Location = ({ size = 24, color = '#8D91A0' }) => {
  return (
    <div className={cn()} style={{ width: size, height: size }}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_633_366)'>
          <path d='M24 0H0V24H24V0Z' fill='white' fillOpacity='0.01' />
          <path
            d='M12 22C12 22 19.5 16 19.5 9.5C19.5 5.35785 16.1421 2 12 2C7.85785 2 4.5 5.35785 4.5 9.5C4.5 16 12 22 12 22Z'
            stroke={color}
            strokeWidth='1.6'
            strokeLinejoin='round'
          />
          <path
            d='M12 12.5C13.6568 12.5 15 11.1568 15 9.5C15 7.84315 13.6568 6.5 12 6.5C10.3432 6.5 9 7.84315 9 9.5C9 11.1568 10.3432 12.5 12 12.5Z'
            stroke={color}
            strokeWidth='1.6'
            strokeLinejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_633_366'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Location;
