import Add from 'assets/icons/Add';
import Button from 'common/components/Button';
import LabelledInput from 'common/components/LabelledInput';
import Select from 'common/components/Select';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import { useGenerateOfferTestsMutation } from 'common/slices/offerApi.slice';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import Right from '../../../assets/icons/Right';
import DisabledTestsState from '../../../common/components/DisabledTestsState';
import EmptyTestsState from '../../../common/components/EmptyTestState';
import { LoadingResultState } from '../../../common/components/LoadingResultState';
import Toggle from '../../../common/components/Toggle';
import { cn } from '../../../common/utils';
import { OfferTestItem } from '../../components/OfferTestItem';
import NewTestModal from './NewTestModal';

export const TestsStep = ({
  availabIndustries,
  description,
  tests,
  setTests,
  setDisableNavigationButtons,
  assistant_id,
  setAssistantID,
  isUpdateMode,
}) => {
  const { t } = useTranslation('offers\\new');
  const toast = useToast();
  const formik = useFormikContext();
  const [isNewTestOpen, setNewTestOpen] = useState(false);
  const sourceAI = 'ai-suggested';

  const [generateOfferTests, { isLoading }] = useGenerateOfferTestsMutation();

  const handleClick = async () => {
    try {
      setDisableNavigationButtons(true);
      const { positionName, industry, tone, keywords } = formik.values;
      const response = await generateOfferTests({
        title: positionName,
        industry: t('industry.' + industry.value),
        keywords: keywords,
        tone: tone.value,
        description: description,
        assistant_id: assistant_id,
      }).unwrap();

      setAssistantID(response.assistant_id);

      const manualTests = tests.filter((test) => test.source !== sourceAI);

      setTests([
        ...manualTests,
        ...response.tests.map((test) => {
          return {
            name: test.name,
            description: test.description,
            source: sourceAI,
          };
        }),
      ]);
    } catch (err) {
      console.log(err);
      switch (err?.status) {
        case 400:
          toast.newToast('negative', err?.data.errors[0].detail);
          break;
        case 403:
          toast.newToast('negative', err?.data.errors[0].detail);
          break;
        case 502:
          toast.newToast('negative', err?.data.errors[0].detail);
          break;
        default:
          toast.newToast('negative', t('toasts.something-wrong'));
      }
    } finally {
      setDisableNavigationButtons(false);
    }
  };

  const handleClickAddTest = async () => {
    setNewTestOpen(true);
  };

  const handleClickDeleteTest = async (index) => {
    let remainingTests = [...tests];
    remainingTests.splice(index, 1);
    setTests([...remainingTests]);
  };

  const handleToggleSkipTests = (isActive) => {
    formik.setFieldValue('skipTests', isActive);
    formik.setFieldValue('hideRankColumn', isActive);
  };

  return (
    <React.Fragment>
      <div className='w-2/3 pb-8'>
        <div className='warning-banner rounded-lg bg-[#F07D6633] text-dark-liver p-4 mt-4'>
          <h1 className='text-vibrant-orange font-semibold'>
            {t('testWarningTitle')}
          </h1>
          <p>{t('testWarning')}</p>
        </div>

        <div className='flex items-center justify-between mt-8'>
          <h3 className='text-sm font-semibold text-dark-liver uppercase flex items-center relative'>
            {t('evaluationTitle')}
            <span
              className={cn(
                'ml-1 px-1 text-[10px] font-semibold rounded-full relative -top-1',
                'bg-[#FFEFEA] text-vibrant-orange',
              )}
            >
              AI
            </span>
          </h3>
          <Toggle
            className=''
            isActive={formik.values.skipTests}
            onClick={handleToggleSkipTests}
            labelActive={t('skipTests')}
            labelInactive={t('skipTests')}
            disabled={isUpdateMode}
            labelSide='left'
          />
        </div>

        <p className='text-sm text-cool-grey font-normal mt-2'>
          {t('evaluationDescription')}
        </p>

        <div className='flex w-full gap-4 mt-4'>
          <div className='w-1/2 pt-2'>
            <LabelledInput
              label={t('position-name')}
              name='positionName'
              type='text'
              placeholder={t('position-name')}
              disabled={true}
              height='h-[48px]'
            />
          </div>

          <div className='w-1/2'>
            <Select
              name='industry'
              label={t('industryLabel')}
              options={availabIndustries}
              isWithAvatar={false}
              translationPath='offers\\new'
              isDisabled={true}
            />
          </div>
        </div>

        <div className='flex items-center justify-end mt-4 gap-4'>
          <Button
            type='button'
            variant='secondary'
            disabled={isLoading || formik.values.skipTests}
            onClick={handleClick}
            className={formik.values.skipTests ? 'invisible' : 'visible'}
          >
            {t('generateButton')}
            <Right size={16} />
          </Button>
        </div>

        {formik.values.skipTests ? (
          <div className='mt-6'>
            <DisabledTestsState />
          </div>
        ) : (
          <React.Fragment>
            <div className='flex items-center justify-between mt-4'>
              <div className='space-y-2'>
                <h3 className='text-sm font-semibold text-cool-grey uppercase'>
                  {t('evaluationTitle')}
                </h3>
                <p className='text-sm text-cool-grey font-normal'>
                  {t('testsListMessage')}
                </p>
              </div>

              <Button
                type='button'
                size='icon'
                variant='primary'
                disabled={isLoading}
                onClick={handleClickAddTest}
              >
                <Add size='16px' color='#FFFFFF' />
              </Button>
            </div>

            {isLoading ? (
              <div className='flex gap-3'>
                <LoadingResultState text={t('generatingTests')} />
              </div>
            ) : tests.length > 0 ? (
              <div className='flex w-full gap-4'>
                <div className='w-full flex flex-col'>
                  {tests.map((test, index) => (
                    <OfferTestItem
                      key={index}
                      name={test.name}
                      description={test.description}
                      isRemovable={true}
                      onDeleteClick={() => handleClickDeleteTest(index)}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className='mt-6'>
                <EmptyTestsState />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      <NewTestModal
        isOpen={isNewTestOpen}
        setIsOpen={setNewTestOpen}
        tests={tests}
        setTests={setTests}
      />
    </React.Fragment>
  );
};
