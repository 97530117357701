import {
  selectCurrentCompany,
  selectCurrentToken,
  selectUserCompanies,
  setCurrentCompany,
} from 'common/slices/auth.slice';
import { isEmpty } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';

const RequireAuth = () => {
  const location = useLocation();
  const { companySlug } = useParams();
  const dispatch = useDispatch();

  const token = useSelector(selectCurrentToken);
  const userCompanies = useSelector(selectUserCompanies);
  const currentCompany = useSelector(selectCurrentCompany);

  if (!token || isEmpty(userCompanies)) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  if (companySlug) {
    const matchedCompany = userCompanies.find(
      (company) => company.slug === companySlug,
    );
    if (matchedCompany) {
      dispatch(setCurrentCompany(matchedCompany));
      return <Outlet />;
    }
  }

  if (token && currentCompany) {
    return <Outlet />;
  }

  return <Navigate to='/company-selector' state={{ from: location }} replace />;
};

export default RequireAuth;
