import Loader from 'common/components/Loader';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import { useGetOfferSubmissionsBySlugQuery } from 'common/slices/offerApi.slice';
import {
  useUpdateFavoriteStatusMutation,
  useUpdatePhaseMutation,
} from 'common/slices/submissionApi.slice';
import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';

import InterviewModal from '../modals/InterviewModal';
import KanbanColumn from './KanbanColumn';

const KanbanBoard = ({
  offer,
  itemsCompact,
  handleHired,
  searchTerm,
  questions,
  isFavoriteParam,
}) => {
  const toast = useToast();
  const { offerSlug } = useParams();
  const { t } = useTranslation('offers\\details');

  const [itemsExpanded, setItemsExpanded] = useState(false);
  const [candidatesPage, setCandidatesPage] = useState(1);
  const [evaluationPage, setEvaluationPage] = useState(1);
  const [classificationPage, setClassificationPage] = useState(1);
  const [interviewPage, setInterviewPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingDrag, setPendingDrag] = useState(null);

  const [updatePhase, { isLoading: isUpdating }] = useUpdatePhaseMutation();

  const [updateFavoriteStatus, { isLoading: isUpdatingFavoriteStatus }] =
    useUpdateFavoriteStatusMutation();

  const {
    data: candidates,
    isFetching: isLoadingCandidates,
    refetch: refetchAll,
  } = useGetOfferSubmissionsBySlugQuery({
    offerSlug,
    phase: 'all',
    isFavorite: isFavoriteParam,
    orderBy: '-is_favorite, -submission_ai__weighted_ranking, -updated_at',
    search: searchTerm,
    withDetail: !itemsCompact,
    data: questions,
    page: candidatesPage,
  });

  const {
    data: evaluation,
    isFetching: isLoadingEvaluation,
    refetch: refetchEvaluate,
  } = useGetOfferSubmissionsBySlugQuery({
    offerSlug,
    phase: 'evaluate',
    isFavorite: isFavoriteParam,
    orderBy: '-is_favorite, -submission_ai__weighted_ranking, -updated_at',
    search: searchTerm,
    withDetail: !itemsCompact,
    data: questions,
    page: evaluationPage,
  });

  const {
    data: classification,
    isFetching: isLoadingClassification,
    refetch: refetchRank,
  } = useGetOfferSubmissionsBySlugQuery({
    offerSlug,
    phase: 'rank',
    isFavorite: isFavoriteParam,
    orderBy:
      '-is_favorite, -avg_assessment, -submission_ai__weighted_ranking, -updated_at',
    search: searchTerm,
    withDetail: !itemsCompact,
    data: questions,
    page: classificationPage,
  });

  const {
    data: interview,
    isFetching: isLoadingInterview,
    refetch: refetchInterview,
  } = useGetOfferSubmissionsBySlugQuery({
    offerSlug,
    phase: 'interview',
    isFavorite: isFavoriteParam,
    orderBy:
      '-is_favorite, -is_hired, -avg_assessment, -submission_ai__weighted_ranking, -updated_at',
    search: searchTerm,
    withDetail: !itemsCompact,
    data: questions,
    page: interviewPage,
  });

  const isLoading =
    isLoadingCandidates ||
    isLoadingEvaluation ||
    isLoadingClassification ||
    isLoadingInterview;

  const [columns, setColumns] = useState({});

  useEffect(() => {
    refetchAll();
    refetchEvaluate();
    refetchRank();
    refetchInterview();
  }, [isFavoriteParam]);

  useEffect(() => {
    if (!itemsCompact && !itemsExpanded) {
      refetchAll();
      refetchEvaluate();
      refetchRank();
      refetchInterview();
      setItemsExpanded(true);
    }
  }, [itemsCompact]);

  useEffect(() => {
    setColumns({
      candidates: {
        id: 'candidates',
        list: candidates?.items ?? [],
        total: candidates?.total,
        page: candidates?.page,
        pages: candidates?.pages,
      },
      evaluation: {
        id: 'evaluation',
        list: evaluation?.items ?? [],
        total: evaluation?.total,
        page: evaluation?.page,
        pages: evaluation?.pages,
      },
      classification: offer.skip_tests
        ? null
        : {
            id: 'classification',
            list: classification?.items ?? [],
            total: classification?.total,
            page: classification?.page,
            pages: classification?.pages,
          },
      interview: {
        id: 'interview',
        list: interview?.items ?? [],
        total: interview?.total,
        page: interview?.page,
        pages: interview?.pages,
      },
    });
  }, [candidates, evaluation, classification, interview, offer.skip_tests]);

  const handlePhaseChange = async (submissionId, startColumn, endColumn) => {
    const columnMap = {
      candidates: 'all',
      evaluation: 'evaluate',
      classification: 'rank',
      interview: 'interview',
    };

    try {
      await updatePhase({
        submissionId: submissionId,
        nextPhase: columnMap[endColumn],
      }).unwrap();

      toast.newToast(
        'positive',
        t('kanban.toasts.success', {
          start: t(`kanban.${startColumn}`),
          end: t(`kanban.${endColumn}`),
        }),
      );

      return true;
    } catch (err) {
      switch (err?.status) {
        case 400:
          toast.newToast('negative', err?.data.errors[0].detail);
          break;
        case 403:
          toast.newToast('negative', err?.data.errors[0].detail);
          break;
        default:
          toast.newToast('negative', t('kanban.toasts.something-wrong'));
      }

      return false;
    }
  };

  const handleFavoriteChanged = async (
    columnId,
    submissionSlug,
    isFavoriteNewStatus,
  ) => {
    await updateFavoriteStatus({
      submissionSlug: submissionSlug,
      isFavorite: isFavoriteNewStatus,
    })
      .unwrap()
      .then((response) => {
        toast.newToast('positive', t('toasts.success-mark-favorite'));
        refreshColumn(columnId);
      })
      .catch((err) => {
        switch (err?.status) {
          case 403:
            toast.newToast('negative', t('toasts.forbidden'));
            break;
          default:
            console.error(err);
            toast.newToast('negative', t('toasts.something-wrong'));
        }
      });
  };

  const onDragStart = (start) => {
    const sourceColumn = columns[start.source.droppableId];

    setColumns((state) => {
      return {
        ...state,
        [sourceColumn.id]: {
          ...sourceColumn,
          isDropDisabled: true,
        },
      };
    });
  };

  const onDragEnd = async ({ source, destination }) => {
    if (!destination || source.droppableId === destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      setColumns((prevColumns) => ({
        ...prevColumns,
        [sourceColumn.id]: {
          ...sourceColumn,
          isDropDisabled: false, // Re-enable dragging
        },
      }));
      return;
    }

    const start = columns[source.droppableId];
    const end = columns[destination.droppableId];

    const movingItem = start.list[source.index];

    if (end.id === 'interview') {
      setPendingDrag({
        submissionId: movingItem.id,
        startColumn: start.id,
        endColumn: end.id,
      });
      setIsModalOpen(true);
      return;
    }

    await handlePhaseChange(movingItem.id, start.id, end.id);
  };

  const handleLoadMore = (column) => {
    if (column.id === 'candidates') {
      setCandidatesPage(candidatesPage + 1);
    }
    if (column.id === 'evaluation') {
      setEvaluationPage(evaluationPage + 1);
    }
    if (column.id === 'classification') {
      setClassificationPage(classificationPage + 1);
    }
    if (column.id === 'interview') {
      setInterviewPage(interviewPage + 1);
    }
  };

  const handleConfirmDrag = async () => {
    if (pendingDrag) {
      await handlePhaseChange(
        pendingDrag.submissionId,
        pendingDrag.startColumn,
        pendingDrag.endColumn,
      );
    }
    setIsModalOpen(false);
    setPendingDrag(null);
  };

  const handleCancelDrag = () => {
    if (pendingDrag) {
      const { startColumn } = pendingDrag;
      setColumns((prevColumns) => ({
        ...prevColumns,
        [startColumn]: {
          ...prevColumns[startColumn],
          isDropDisabled: false, // Re-enable dragging
        },
      }));
    }
    setIsModalOpen(false);
    setPendingDrag(null);
  };

  const refreshColumn = (columnId) => {
    if (columnId === 'candidates') refetchAll();
    if (columnId === 'evaluation') refetchEvaluate();
    if (columnId === 'classification') refetchRank();
    if (columnId === 'interview') refetchInterview();
  };

  return (
    <div className='w-full flex-1 overflow-hidden mt-6'>
      <div className='relative flex w-full h-full pb-4 gap-4'>
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          {Object.values(columns)
            .filter((col) => col !== null) // Filter out null columns
            .map((col) => (
              <KanbanColumn
                col={col}
                key={col.id}
                offer={offer}
                isLoading={isLoading}
                itemsCompact={itemsCompact}
                handleHired={handleHired}
                handleLoadMore={() => handleLoadMore(col)}
                handleFavoriteChanged={handleFavoriteChanged}
              />
            ))}
        </DragDropContext>
        {(isUpdating || isUpdatingFavoriteStatus) && <Loader />}
      </div>
      <InterviewModal
        isOpen={isModalOpen}
        onConfirm={handleConfirmDrag}
        onCancel={handleCancelDrag}
      />
    </div>
  );
};

export default KanbanBoard;
